<template>
    <!-- 异常分拣 -->
    <div class="sorting-container">
        <div class="leftsorting">
            <logotop></logotop>
             <classification style="margin-top:10px" :data="classifylist" @change="changeclassifyFun"></classification>
        </div>
        <div class="rightsorting">
            <sortingformdifferent  class="sortingformbox"></sortingformdifferent>
            <div class="sortingBox" style="margin-top:10px">
                <div v-for="(item,index) in productList" class="producttabItem" :style="{marginRight:(index==5?'0px':'8px')}">
                    <product-tab :data="item" :color="item.status==1?'#34BC00':'#A6A6A6'"></product-tab>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
  import logotop from "@/components/logotop"; //顶部logo 
  import sortingformdifferent from "@/components/sortingformdifferent"; //顶部查询
  import classification from "@/components/classification"; //顶部查询
  import productTab from "@/components/productTab"; //顶部查询
  
  export default {
    name: "sorting",
    components: {
       logotop,
       sortingformdifferent,
       classification,
       productTab
     },
    data() {
      return {
        productList:[{status:2,statusname:'差异分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,outstock:0},{status:2,statusname:'差异分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:0},{status:2,statusname:'差异分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:0},{status:2,statusname:'差异分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:0},{status:2,statusname:'差异分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:0},{status:2,statusname:'差异分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:0},{status:2,statusname:'差异分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:0}],
        classifylist:[{key:'',name:'全部'},{key:1,name:'数量变化'},{key:2,name:'商品替换'},{key:3,name:'价格变化'}],   
      }
    },
    methods: {
      onSubmit() {
        
      },
      changeclassifyFun(e){
        console.log(e)  
      },
    }
  }
</script>

<style scoped lang="less">
    .producttabItem{
        display:inline-block;
        margin-bottom:10px
    }
    .leftsorting{
        width:205px;
        margin-right:10px;
    }
    .rightsorting{
        width:1047px;
    }
    .sortingBox{
        width:1047px;
        height:640px;
         overflow-y: scroll;
    }
   .sorting-container{
      background: #F1F2F2; 
       height:800px;
      width:100%;
      display:flex;
      padding:10px;
   }
   .sortingformbox{
     
   }
</style>