<template>
  <div class="sortingform">
      <a-form-model layout="inline" class="sortingForm">
          <a-form-model-item class="itemFormItem">
             <a-button class="back-btn bgBlue" size="large" type="primary" @click="go_back">
               <a-icon type="arrow-left" /> 返回
             </a-button>
          </a-form-model-item>
          <a-form-model-item  class="itemFormItem">
              <a-date-picker @change="onChange" placeholder="2021-08-10" style="width:180px"/>
          </a-form-model-item>
          <a-form-model-item  class="itemFormItem">
              <a-input-search placeholder="输入订单号" @search="onSearch" style="width:230px"/>
          </a-form-model-item>
          <a-form-model-item  class="itemFormItem">
               <a-input-search placeholder="输入商品名称" @search="onSearch" style="width:230px;margin-left:50px"/>
          </a-form-model-item>
          <a-form-model-item class="itemFormItem">
              <a-button class="query-btn bgBlue" size="large" type="primary">
                  查询
              </a-button>
          </a-form-model-item>
      </a-form-model>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: ''
  },
  methods: {
      go_back(){
          this.$router.back()
      },
      onSearch(){
          
      },
      onChange(){
          
      }
  }
}
</script>
<style>
    .ant-form-inline .ant-form-item{
        margin-right:15px;
    }
   .sortingForm .ant-input{
        height:80px !important; 
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000;
    }
    .sortingForm .ant-input-search-icon{
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #B2B2B2;
    }
</style>
<style scoped lang="less">
    .sortingform{
         background: #fff;
         display: flex;
         padding:25px;
         align-items: center;
         width:100%;
     }
    .logo-top-img{
        width: 16%;
        height:80px;
        background: #FFFFFF;
        box-shadow: 0px 2px 11px 0px rgb(177 188 199 / 15%);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        .logo-img{
            width:77.5%;
            
        }
    }
    .bgBlue{
       background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
       border-radius: 5px; 
       border:none;
       font-size: 20px;
       font-family: PingFangSC-Semibold, PingFang SC;
       font-weight: 600;
       color: #FFFFFF;
        height:80px;
       outline:none;
       padding:0px 28px;
    }
    .bgDotted{
        background: #fff;
         border-radius: 5px; 
         
         border:none;
        height:80px;
         outline:none;
         color:#203EA0;
         border: 1px solid #C6CBD4;
    }
    .itemFormItem{
         height:80px;
    }
</style>
